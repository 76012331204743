import React from 'react';

class CommunicationPanel extends React.Component {
  componentDidMount() {
    if (
      !document.querySelector('#floatingContacts')
    ) {
      const floatingContacts = document.createElement('div');
      floatingContacts.id = 'floatingContacts';
      document.body.appendChild(floatingContacts);

      // eslint-disable-next-line global-require
      const CommunicationPanelImport = require('@uvocore-front/communication-panel').default;
      // eslint-disable-next-line no-unused-vars,no-new
      window.communicationPanel = new CommunicationPanelImport();
    }
  }

  render() {
    return null;
  }
}

export default CommunicationPanel;
