import PropTypes from 'prop-types';
import classNames from 'classnames';

import './UserBlockButton.scss';


export default function makeUserBlockButton(React) {
  const UserBlockButton = ({
    urlKey,
    href,
    onClick,
  }) => (
    <a
      href={href}
      onClick={onClick}
      className={classNames(
        'header__sign-in__btn',
        'btn',
        'btn_without-bg',
        'btn_white',
        'btn_small',
        'UserBlockButton',
        `UserBlockWhois--${urlKey}`,
      )}
    >
      My orders
    </a>
  );

  UserBlockButton.propTypes = {
    urlKey: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
  };

  UserBlockButton.defaultProps = {
    href: '/',
    onClick: undefined,
  };

  return UserBlockButton;
}
