/* globals __CWT_CD_main__, __CWT_CD_app__, __CWT_CD_widgets__ */
import { stringify } from 'query-string';

/**
 * @typedef {function} CWT~ComponentCreator
 * @param {React} React
 */

/**
 * @typedef {object} CWT~FooterLinkComponentProps
 * @prop {string} text
 * @prop {string} to
 */

/**
 * @typedef {object} CWT~FooterBadgeComponentProps
 * @prop {string} title
 * @prop {string} href
 * @prop {string} imgSrc
 * @prop {string} imgAlt
 */

/**
 * @typedef {object} CWT~FooterComponentProps
 * @prop {[CWT~FooterLinkComponentProps]=} links
 * @prop {CWT~FooterBadgeComponentProps=} badge
 * @prop {string=} copyrightText
 */

/**
 * @typedef {object} CWT~Components
 * @prop {CWT~ComponentCreator} makeUserBlockButton
 * @prop {CWT~ComponentCreator} makeUserBlockWhois
 * @prop {CWT~ComponentCreator} makeDashboardTopHeader
 * @prop {CWT~ComponentCreator} makeDashboardNav
 * @prop {CWT~ComponentCreator} makeAuthPage
 * @prop {CWT~FooterComponentProps} footerOptions
 */

/**
 * @typedef {object} CWT~AppConfig
 * @prop {HTMLElement} target
 * @prop {CWT~Components} rcappComponents
 * @prop {string=app} app - values: `app`, `standalone-form`
 * @prop {string=newOrder} orderformPurpose - values: `newOrder`, `newInquiry`
 * @prop {string=} defaultCoutry
 * @prop {string=} siteEmail
 * @prop {number=} topPadding
 */

/**
 * @typedef {object} CWT~WidgetUserBlockConfig
 * @prop {HTMLElement} target
 * @prop {CWT~Components} rcappComponents
 * @prop {boolean} showMyordersButton
 */

/**
 * @typedef {object} CWT~AppQuery
 * @prop {number} siteId
 * @prop {string} containerNode
 * @prop {string=} serverEnv
 * @prop {string=app} app - values: `app`, `standalone-form`
 * @prop {string=} basename
 * @prop {string=newOrder} orderformPurpose - values: `newOrder`, `newInquiry`
 * @prop {string=} defaultCoutry
 * @prop {string=} siteEmail
 * @prop {number=} topPadding
 * @prop {boolean=} useRouter
 */

/**
 * @typedef {object} CWT~WidgetUserBlockQuery
 * @prop {number} siteId
 * @prop {string=} serverEnv
 * @prop {string=} basename
 * @prop {boolean} showMyordersButton
 */

const BASE_URL = '/assets';
const APP_URL = '/get_rcapp/';
const WIDGET_USER_BLOCK_URL = '/get_user_block/';

const getStore = () => {
  if (typeof __CWT_CD_main__ !== 'undefined') { // eslint-disable-line camelcase
    return __CWT_CD_main__.default; // eslint-disable-line camelcase
  }

  return undefined;
};

const getApp = () => {
  if (typeof __CWT_CD_app__ !== 'undefined') { // eslint-disable-line camelcase
    return __CWT_CD_app__.default; // eslint-disable-line camelcase
  }

  return undefined;
};

const getWidgetUserBlock = () => {
  if (typeof __CWT_CD_widgets__ !== 'undefined') { // eslint-disable-line camelcase
    const { userBlock } = __CWT_CD_widgets__; // eslint-disable-line camelcase

    return userBlock;
  }

  return undefined;
};

const normalizeOptions = options => ({
  basename: 'dashboard',
  siteEmail: 'no-reply@takeawayessay.com', // ToDo remove after https://jira.zae.cc/browse/WCS-11737
  ...options,
});

const load = (path, createQuery) => (options) => {
  const script = document.createElement('script');
  const search = stringify(createQuery(options));

  script.src = `${path}?${search}`;
  script.async = true;

  document.getElementsByTagName('head')[0].appendChild(script);
};

const init = (storeGetter, uiGetter, fallback) => (options) => {
  const store = storeGetter();
  const target = uiGetter();
  const normalizedOptions = normalizeOptions(options);

  if (store && target) {
    store(normalizedOptions);
    target(normalizedOptions);

    return;
  }

  const { rcappComponents } = normalizedOptions;

  window.rcappComponents = { ...window.rcappComponents, ...rcappComponents };

  fallback(normalizedOptions);
};

const destroy = getter => () => {
  const target = getter();
  if (target) target.destroy();
};

const createAppQuery = ({
  target,
  siteEmail, // ToDo remove after https://jira.zae.cc/browse/WCS-11737
  app,
  basename,
  orderformPurpose,
  topPadding,
  useRouter,
}) => ({
  containerNode: target.id,
  app,
  basename,
  siteEmail, // ToDo remove after https://jira.zae.cc/browse/WCS-11737
  orderformPurpose,
  topPadding,
  useRouter,
});

const createWidgetUserBlockQuery = ({
  target,
  showMyordersButton,
  basename,
}) => ({
  containerNode: target.id,
  basename,
  showMyordersButton,
});

export const app = () => ({
  init: init(getStore, getApp, load(`${BASE_URL}${APP_URL}`, createAppQuery)),
  destroy: destroy(getApp),
});

export const widgetUserBlock = () => ({
  init: init(
    getStore,
    getWidgetUserBlock,
    load(`${BASE_URL}${WIDGET_USER_BLOCK_URL}`, createWidgetUserBlockQuery),
  ),
  destroy: destroy(getWidgetUserBlock),
});
