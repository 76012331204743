import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { widgetUserBlock } from '../../utils/cwt';

import makeUserBlockButton from './UserBlockButton';
import makeUserBlockWhois from './UserBlockWhois';

import './UserBlock.scss';


const WIDGET_ID = '___user_block';

class UserBlock extends Component {
  static propTypes = {
    showMyordersButton: PropTypes.bool,
  };

  static defaultProps = {
    showMyordersButton: false,
  };

  constructor(props) {
    super(props);

    this.widget = widgetUserBlock();
  }

  componentDidMount() {
    const { showMyordersButton } = this.props;

    this.widget.init({
      target: this.widgetElement,
      rcappComponents: {
        makeUserBlockButton,
        makeUserBlockWhois,
      },
      showMyordersButton,
    });
  }

  componentWillUnmount() {
    this.widget.destroy();
  }

  render() {
    return (
      <div
        id={WIDGET_ID}
        className="user-block"
        ref={(ref) => { this.widgetElement = ref; }}
      />
    );
  }
}

export default UserBlock;
