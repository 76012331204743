// @todo enable next eslint rules
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './UserBlockWhois.scss';


export default function makeUserBlockWhois(React) {
  const UserBlockWhois = ({
    menuOpen,
    onMouseEnter,
    onMouseLeave,
    onClick,
    children,
  }) => (
    <span
      role="button"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={classNames(
        'UserBlockWhois',
        menuOpen && 'UserBlockWhois--open',
      )}
      tabIndex="0"
    >
      <span className="UserBlockWhois__avatar-container">
        <svg
          id="UserBlockWhois__avatar"
          className="UserBlockWhois__avatar"
          xmlns="http://www.w3.org/2000/svg"
          width="27.44"
          height="25.38"
          viewBox="0 0 27.44 25.38"
        >
          <path
            className="UserBlockWhois__avatar__path"
            fill="#000000"
            d="M27.44,10.85c0-2-2-3.73-5.59-4.79A12.16,12.16,0,0,0,18.06.45c-1.38-1.06-2.74,0-3.47.57a4.1,4.1,0,0,1-.86.56A3.29,3.29,0,0,1,12.82,1C12.07.47,10.68-.56,9.3.53,6.77,2.54,5,6.24,4.67,10.41a1.27,1.27,0,0,0,1.14,1.37h.11a1.26,1.26,0,0,0,1.25-1.15c.3-3.41,1.63-6.39,3.54-8,.21.13.41.27.61.42a4.16,4.16,0,0,0,2.41,1A4,4,0,0,0,16.13,3c.17-.13.4-.31.56-.41,1.94,1.61,3.28,4.59,3.59,8a1.26,1.26,0,0,0,2.51-.23h0c-.05-.48-.11-1-.19-1.42,1.66.7,2.32,1.45,2.32,1.86,0,1.14-4,3.34-11.2,3.34S2.52,12,2.52,10.84a1.26,1.26,0,0,0-2.52,0H0c0,2.35,2.58,4,6.14,4.95V18a7.56,7.56,0,0,0,7.73,7.37A7.57,7.57,0,0,0,21.61,18V15.71C25,14.76,27.44,13.13,27.44,10.85ZM19.09,18a5.05,5.05,0,0,1-5.21,4.86A5,5,0,0,1,8.67,18V16.32a31.66,31.66,0,0,0,5.06.38,33.3,33.3,0,0,0,5.36-.43V18Z"
          />
        </svg>
      </span>
      {children}
    </span>
  );

  UserBlockWhois.propTypes = {
    menuOpen: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
  };

  UserBlockWhois.defaultProps = {
    menuOpen: false,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    onClick: undefined,
    children: undefined,
  };

  return UserBlockWhois;
}
